import React from 'react'
import logo from './logo.svg';
import './App.css';

function App() {
  const [store, setstore] = React.useState({
    title : 'HomeAR',
    subtitle : 'Discover real estate with augmented reality',
    short_description : 
    "A real estate company that utilizes augmented reality technology to revolutionize the property discovery process. Experience properties like never before with vivid and immersive virtual tours, bringing the search for your dream home to life. Find your perfect match with ease, all from the comfort of your own space.",
    contact : {
        description : '',
        phone : '+212661131602',
        email : 'contact@homear.es',
        address : '59, Avenue Ibn Sina N 11, Agdal, Grand Casablanca, Rabat, Morocco, 12515'
    }
  });


  return (
    <>
       <div className="jumbotron jumbotron-fluid" id="banner" style={{backgroundImage: 'url(img/etienne-beauregard-riverin-B0aCvAVSX8E.jpg'}}>
        <div className="container text-center text-md-left">
            <header>
                <div className="row justify-content-between">
                    <div className="col-2">
                        <img src="img/logo.png" alt="logo"/>
                    </div>
                </div>
            </header>
            <h1 data-aos="fade" data-aos-easing="linear" data-aos-duration="1000" data-aos-once="true" className="display-3 text-white font-weight-bold my-5">
            	{store.title || ''}<br />
            	{store.subtitle || ''}
            </h1>
            <p style={{fontWeight : 'bold'}} data-aos="fade" data-aos-easing="linear" data-aos-duration="1000" data-aos-once="true" className="lead text-white my-4">
              {store.short_description || ''}    
            </p>
            {/* <a href="#" data-aos="fade" data-aos-easing="linear" data-aos-duration="1000" data-aos-once="true" className="btn my-4 font-weight-bold atlas-cta cta-green">Discover Announces</a> */}
        </div>
    </div>
    {/* <!-- three-blcok --> */}
    <div className="container my-5 py-2">
        <h2 className="text-center font-weight-bold my-5">Smartest protection for your site</h2>
        <p style={{fontWeight : 'bold', textAlign : 'center'}} data-aos="fade" data-aos-easing="linear" data-aos-duration="1000" data-aos-once="true" className="lead text-black my-4">
            {store.short_description || ''}    
        </p>
    </div>
    <div className="container my-5 py-2">
        <h2 className="text-center font-weight-bold my-5">coming soon</h2>
    </div>
    <div className="jumbotron jumbotron-fluid" id="contact" style={{backgroundImage: 'url(img/contact-bk.jpg)'}}>
        <div className="container my-5">
            <div className="row justify-content-between">
                <div className="col-md-6 text-white">
                    <h2 className="font-weight-bold">Contact Us</h2>
                    <p className="my-4">
                        {store?.contact?.description || ''}
                    </p>
                    <ul className="list-unstyled">
                        <li>Email : {store?.contact?.email || ''}</li>
                        <li>Phone : {store?.contact?.phone || ''}</li>
                        <li>Address : {store?.contact?.address || ''}</li>
                    </ul>
                </div>
                <div className="col-md-6">
                    <form>
                    	<div className="row">
	                        <div className="form-group col-md-6">
	                            <label htmlFor="name">Your Name</label>
	                            <input type="name" className="form-control" id="name"/>
	                        </div>
	                        <div className="form-group col-md-6">
	                            <label htmlFor="Email">Your Email</label>
	                            <input type="email" className="form-control" id="Email"/>
	                        </div>
	                    </div>
	                    <div className="form-group">
	                        <label htmlFor="message">Message</label>
	                        <textarea className="form-control" id="message" rows="3"></textarea>
	                    </div>
                        <button type="submit" className="btn font-weight-bold atlas-cta atlas-cta-wide cta-green my-3">Submit</button>
                    </form>
                </div>
            </div>
        </div>
    </div>

	{/* <!-- copyright --> */}
	<div className="jumbotron jumbotron-fluid" id="copyright">
        <div className="container">
            <div className="row justify-content-between">
            	<div className="col-md-6 text-white align-self-center text-center text-md-left my-2">
                    Copyright © 2022 Brokerage & Reim
                </div>
                <div className="col-md-6 align-self-center text-center text-md-right my-2" id="social-media">
                    <a href="#" className="d-inline-block text-center ml-2">
                    	<i className="fa fa-facebook" aria-hidden="true"></i>
                    </a>
                    <a href="#" className="d-inline-block text-center ml-2">
                    	<i className="fa fa-twitter" aria-hidden="true"></i>
                    </a>
                    <a href="#" className="d-inline-block text-center ml-2">
                    	<i className="fa fa-medium" aria-hidden="true"></i>
                    </a>
                    <a href="#" className="d-inline-block text-center ml-2">
                    	<i className="fa fa-linkedin" aria-hidden="true"></i>
                    </a>
                </div>
            </div>
        </div>
    </div>
    
    </>
  );
}

export default App;
